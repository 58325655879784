import axios from 'axios'; // POST
import cachios from 'cachios'; // GET

class Api {
  baseUrl = 'https://bepensive.com/razor-capital/wp-json';

  getHome = async () => {
    return await cachios.get(this.baseUrl + `/wp/v2/pages?slug=front-page`);
  };

  getCompanies = async () => {
    return await cachios.get(this.baseUrl + `/wp/v2/companies?per_page=50`);
  };

  getLatestNews = async () => {
    return await cachios.get(this.baseUrl + `/wp/v2/posts?per_page=50`);
  };

  getTeam = async () => {
    return await cachios.get(this.baseUrl + `/acf/v3/options/options`);
  };

  getAbout = async () => {
    return await cachios.get(this.baseUrl + `/wp/v2/pages?slug=about`);
  };

  getInvestments = async () => {
    return await cachios.get(this.baseUrl + `/wp/v2/pages?slug=investments`);
  };

  getTeamPageData = async () => {
    return await cachios.get(this.baseUrl + `/wp/v2/pages?slug=team`);
  };

  getTeamMembersData = async () => {
    return await cachios.get(this.baseUrl + `/wp/v2/team?per_page=50`);
  };

  getMonsoonsData = async () => {
    return await cachios.get(this.baseUrl + `/wp/v2/pages?slug=monsoon-tech`);
  };

  getOptions = async () => {
    return await cachios.get(this.baseUrl + `/acf/v3/options/options`);
  };

  sendContactForm = async (data) => {
    const formData = new FormData();

    for (const key in data) {
      const val = data[key];
      formData.append(key, val);
    }

    const url = this.baseUrl + `/contact-form-7/v1/contact-forms/108/feedback`;
    return await axios.post(url, formData);
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Api();

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { TextField } from '@material-ui/core';

import Api from '../api/api';

import submitLeftArrow from './../assets/img/home-head-arrow.png';
import leftArrow from './../assets/img/contact-arrow-left.png';

class Contact extends React.Component {
  state = {
    isUploaded: '',
    fullnameError: false,
    phonenumberError: false,
    emailError: false,
    formSubmitted: false,
    userContactInfo: {
      email: '',
      phonenumber: '',
      fullname: '',
      file: '',
      message: '',
    },
  };
  componentDidMount() {
    document.title = 'Contact / Razor Capital';
  }

  onUpload = (e) => {
    if (e.target.files && e.target.files.length) {
      this.setState({ isUploaded: e.target.files[0].name });
      this.handleFormOnChange('file', e.target.files[0]);
    } else {
      this.handleFormOnChange('file', '');
    }
  };

  handleFormOnChange = (type, value) => {
    this.setState({
      userContactInfo: {
        ...this.state.userContactInfo,
        [type]: value,
      },
      [type + 'Error']: false,
    });
  };

  isValid = (data) => {
    let isValid = true;
    const requiredFields = ['email', 'phonenumber', 'fullname', 'message'];
    for (const item of requiredFields) {
      if (data[item] === '') {
        isValid = false;
        this.setState({ [item + 'Error']: true });
      }
    }
    return isValid;
  };

  handleFormSubmit = () => {
    const { userContactInfo } = this.state;
    if (this.isValid(userContactInfo)) {
      Api.sendContactForm(userContactInfo).then(() => {
        this.setState({
          userContactInfo: {
            email: '',
            phonenumber: '',
            fullname: '',
            file: '',
            message: '',
          },
        });
      });
    }
  };

  render() {
    const { isUploaded } = this.state;
    console.log();
    return (
      <section className='contact-page'>
        <div className='contact-page-wrapper clear-spacing-ds'>
          <h1>Send us a message</h1>
          <form className='contact-form' autoComplete='off'>
            <TextField
              helperText={this.state.fullnameError ? 'Missing Input' : ''}
              onChange={(e) =>
                this.handleFormOnChange('fullname', e.target.value)
              }
              error={this.state.fullnameError}
              required
              id='outlined-required'
              placeholder='Full Name'
            />
            <TextField
              helperText={this.state.emailError ? 'Missing Input' : ''}
              onChange={(e) => this.handleFormOnChange('email', e.target.value)}
              error={this.state.emailError}
              required
              id='outlined-required'
              placeholder='Email Address'
              type='email'
            />
            <TextField
              helperText={this.state.phonenumberError ? 'Missing Input' : ''}
              onChange={(e) =>
                this.handleFormOnChange('phonenumber', e.target.value)
              }
              error={this.state.phonenumberError}
              required
              id='outlined-required'
              placeholder='Phone Number'
            />
            <div className='upload-file-field file-uploader'>
              <TextField
                type='file'
                className='upload-file-field file-uploader-input'
                onChange={this.onUpload}
              />
              {!isUploaded ? (
                <span className='input-file-title'>Upload Document</span>
              ) : (
                <div className='upload-file-field file-uploaded'>
                  <span
                    onClick={() => {
                      this.setState({ isUploaded: '' });
                    }}
                  >
                    x
                  </span>{' '}
                  {isUploaded}
                </div>
              )}
            </div>
            <div>
              <textarea
                placeholder='Message'
                className={this.state.messageError ? 'error' : ''}
                onChange={(e) =>
                  this.handleFormOnChange('message', e.target.value)
                }
              />
              {this.state.messageError ? (
                <p
                  class='MuiFormHelperText-root Mui-error Mui-required'
                  id='outlined-required-helper-text'
                >
                  Missing Input
                </p>
              ) : (
                <></>
              )}
            </div>
          </form>
          <p
            className='submit-btn  cursor-pointer'
            onClick={this.handleFormSubmit}
          >
            <p>Submit</p>
            <img src={submitLeftArrow} alt='arrow' />
          </p>
        </div>
        <section className='routes clear-spacing-mb'>
          <div>
            <Link to='/about' className='routes-about-btn'>
              <p>About us</p>
              <img src={leftArrow} alt='arrow' />
            </Link>
            <Link to='/investments' className='routes-investments-btn'>
              <p>Portfolio</p>
              <img src={leftArrow} alt='arrow' />
            </Link>
          </div>
        </section>
      </section>
    );
  }
}

export default withRouter((props) => <Contact {...props} />);

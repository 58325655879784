import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import BurgerMenu from './burgerMenu';
import darkLogo from './../../assets/img/dark-logo.svg';

class DesktopHeader extends React.Component {
  componentDidMount() {
    document.title = 'Home / Razor Capital';
    this.listenerToUrlChange();
  }

  listenerToUrlChange = () => {
    const { location } = this.props.history;

    if (location.pathname === '/') {
      document.body.classList.add('orange-header');
    } else {
      document.body.classList.remove('orange-header');
    }

    this.unlisten = this.props.history.listen((location) => {
      if (location.pathname === '/') {
        document.body.classList.add('orange-header');
      } else {
        document.body.classList.remove('orange-header');
      }
    });
  };

  componentWillUnmount() {
    this.unlisten();
  }

  whatPage = () => {
    const { pathname } = this.props.location;
    return pathname;
  };

  render() {
    const page = this.whatPage();
    const isAboutPage = page.indexOf('/about') !== -1 ? 'active' : '';
    const isTeamPage = page.indexOf('/team') !== -1 ? 'active' : '';
    const isInvestemntsPage =
      page.indexOf('/investments') !== -1 ? 'active' : '';
    // const isMonsoonPage = page.indexOf('/monsoon-tech') !== -1 ? 'active' : '';
    const isContactPage = page.indexOf('/contact') !== -1 ? 'active' : '';
    return (
      <header className='desktop-header'>
        <div className='desktop-header-wrapper'>
          <Link to='/'>
            <img src={darkLogo} alt='logo' />
          </Link>
          <div className='nav-bar'>
            <Link className={isAboutPage} to='/about'>
              ABOUT
            </Link>
            <Link className={isTeamPage} to='/team'>
              TEAM
            </Link>
            <Link className={isInvestemntsPage} to='/investments'>
              PORTFOLIO
            </Link>
            {/* <Link className={isMonsoonPage} to='/monsoon-tech'>
              MONSOON TECH
            </Link> */}
            <Link className={isContactPage} to='/contact'>
              CONTACT
            </Link>
          </div>
          <div className='burger-menu'>
            <BurgerMenu />
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter((props) => <DesktopHeader {...props} />);

import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import Api from '../api/api.js';
import leftArrow from './../assets/img/home-head-arrow.png';
import moment from 'moment';

const moveLoadMoreBy = window.innerWidth < 941 ? 2 : 4;
const defaultLoadMoreSize = window.innerWidth < 941 ? 4 : 6;

class Home extends React.Component {
  state = {
    data: '',
    teamData: '',
    newsData: '',
    companiesData: '',
    windowWidth: window.innerWidth,
    companyCounter: 5,
    latestNewsLoaded: defaultLoadMoreSize,
    isLoadMoreVisible: true,
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    document.title = 'Home / Razor Capital';
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    Api.getHome().then((response) =>
      this.setState({ data: response.data[0].acf })
    );

    Api.getTeam().then((response) =>
      this.setState({ teamData: response.data.acf })
    );

    Api.getCompanies().then((response) =>
      this.setState({ companiesData: response.data })
    );

    Api.getLatestNews().then((response) =>
      this.setState({ newsData: response.data })
    );
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth }, () => {
      if (this.state.windowWidth < 941) {
        this.setState({ companyCounter: 4 });
      } else if (this.state.windowWidth < 1720) {
        this.setState({ companyCounter: 5 });
      } else {
        this.setState({ companyCounter: 6 });
      }
    });
  };

  renderCompanies = (data) => {
    const { companyCounter } = this.state;
    const width = companyCounter === 4 ? '50vw' : 100 / companyCounter + 'vw';
    return (
      <section className='company-section'>
        {data.slice(0, companyCounter).map((company, i) => {
          return (
            <div
              key={'company-section' + i}
              className='company-section-item'
              style={{
                backgroundImage: `url(${company.acf.company_featured_image})`,
                width,
                height: width,
              }}
            >
              <img
                src={company.acf.company_logo}
                alt={company.title.rendered}
              />
              <div className='company-section-desc'>
                <img src={company.acf.company_logo} alt='logo' />
                <p
                  className='company-section-desc-text'
                  dangerouslySetInnerHTML={this.createMarkup(
                    company.acf.company_featured_description
                  )}
                />
                <p className='invest-date'>
                  Invested in {company.acf.company_investment_year}
                </p>
              </div>
            </div>
          );
        })}
      </section>
    );
  };

  loadMoreNews = (total) => {
    this.setState(
      { latestNewsLoaded: this.state.latestNewsLoaded + moveLoadMoreBy },
      () => {
        if (!(total - this.state.latestNewsLoaded > 0)) {
          this.setState({ isLoadMoreVisible: false });
        }
      }
    );
  };

  renderLatestNews = (data) => {
    const { latestNewsLoaded, isLoadMoreVisible } = this.state;
    return (
      <section className='latest-news-section'>
        <h1 className='latest-news-section-heading'>Latest news</h1>
        <div className='latest-news-section-container'>
          {data.slice(0, latestNewsLoaded).map((item, i) => {
            const {
              title: { rendered: desc },
              acf: {
                news_image: { url: imgUrl },
                read_time,
                external_link: { url: news_link },
              },
              date_gmt,
            } = item;
            const dateFormat = moment(date_gmt).format('MMMM D, YYYY');
            const readTime = read_time ? (
              <>&nbsp;&nbsp;-&nbsp;&nbsp;{read_time} read</>
            ) : (
              ''
            );
            return (
              <a
                className='latest-news-section-wrapper'
                key={'latest-news-section-wrapper-' + i}
                href={news_link}
                rel='noreferrer'
                target='_blank'
              >
                <img src={imgUrl} alt={desc} />
                <div className='latest-news-section-content'>
                  <p className='latest-news-section-content-date'>
                    {dateFormat}
                    {readTime}
                  </p>
                  <p
                    className='latest-news-section-content-desc'
                    dangerouslySetInnerHTML={this.createMarkup(desc)}
                  />
                </div>
              </a>
            );
          })}
        </div>
        {!isLoadMoreVisible || data.length <= defaultLoadMoreSize ? (
          <></>
        ) : (
          <div className='load-more-content'>
            <div
              className='load-more-content-overlay cursor-pointer'
              onClick={() => this.loadMoreNews(data.length)}
            />
            <span>Load more</span>
          </div>
        )}
      </section>
    );
  };

  createMarkup = (data) => {
    return { __html: data };
  };

  render() {
    const { data, teamData, newsData, companiesData } = this.state;
    return (
      <div className='home-page'>
        {data ? (
          <>
            <section
              className='head-section'
              style={{ backgroundImage: `url(${data.hero_desktop_image})` }}
            >
              <div className='head-section-wrapper'>
                <h1
                  dangerouslySetInnerHTML={this.createMarkup(data.hero_title)}
                />
                <p
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.hero_description
                  )}
                />
                {data.hero_call_to_action.target === '' ? (
                  <>
                    <Link to={data.hero_call_to_action.url}>
                      <p>{data.hero_call_to_action.title}</p>
                      <img src={leftArrow} alt='arrow' />
                    </Link>
                    {data.hero_desktop_icon && (
                      <img src={data.hero_desktop_icon} alt={'Icon'} />
                    )}
                  </>
                ) : (
                  <a
                    href={data.hero_call_to_action.url}
                    rel='noreferrer'
                    target={data.hero_call_to_action.target}
                  >
                    <p>{data.hero_call_to_action.title}</p>
                    <img src={leftArrow} alt='arrow' />
                  </a>
                )}
              </div>
            </section>
            <section className='mission-section'>
              <div className='mission-section-wrapper'>
                <div className='left'>
                  <h1
                    dangerouslySetInnerHTML={this.createMarkup(
                      data.about_section_title
                    )}
                  />
                  <p
                    dangerouslySetInnerHTML={this.createMarkup(
                      data.about_section_description
                    )}
                  />
                </div>
                {data.about_section_call_to_action.target === '' ? (
                  <Link to={data.about_section_call_to_action.url}>
                    <p>{data.about_section_call_to_action.title}</p>
                    <img src={leftArrow} alt='arrow' />
                  </Link>
                ) : (
                  <a
                    href={data.about_section_call_to_action.url}
                    rel='noreferrer'
                    target={data.about_section_call_to_action.target}
                  >
                    <p>{data.about_section_call_to_action.title}</p>
                    <img src={leftArrow} alt='arrow' />
                  </a>
                )}
              </div>
            </section>
            <section className='game-section'>
              <div className='game-section-wrapper'>
                <h1
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.our_gamechangers_section_title
                  )}
                />
                <p
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.our_gamechangers_section_description
                  )}
                />
                {data.our_gamechangers_section_call_to_action.target === '' ? (
                  <Link to={data.our_gamechangers_section_call_to_action.url}>
                    <p>{data.our_gamechangers_section_call_to_action.title}</p>
                    <img src={leftArrow} alt='arrow' />
                  </Link>
                ) : (
                  <a
                    href={data.our_gamechangers_section_call_to_action.url}
                    rel='noreferrer'
                    target={data.our_gamechangers_section_call_to_action.target}
                  >
                    <p>{data.our_gamechangers_section_call_to_action.title}</p>
                    <img src={leftArrow} alt='arrow' />
                  </a>
                )}
              </div>
            </section>
            {companiesData ? this.renderCompanies(companiesData) : ''}
            {/* {newsData ? this.renderLatestNews(newsData) : <></>} */}
            {teamData ? (
              <section className='team-section clear-spacing-mb clear-spacing-ds'>
                <div
                  className='team-section-wrapper'
                  style={
                    window.innerWidth > 940
                      ? {
                          backgroundImage: `url(${teamData.footer_section_image})`,
                        }
                      : {
                          backgroundImage: `url(${teamData.footer_section_image_mobile})`,
                        }
                  }
                >
                  <span
                    dangerouslySetInnerHTML={this.createMarkup(
                      teamData.footer_section_title
                    )}
                  />
                  {teamData.footer_section_call_to_action.target === '' ? (
                    <Link to={teamData.footer_section_call_to_action.url}>
                      <p>{teamData.footer_section_call_to_action.title}</p>
                      <img src={leftArrow} alt='arrow' />
                    </Link>
                  ) : (
                    <a
                      href={teamData.footer_section_call_to_action.url}
                      rel='noreferrer'
                      target={teamData.footer_section_call_to_action.target}
                    >
                      <p>{teamData.footer_section_call_to_action.title}</p>
                      <img src={leftArrow} alt='arrow' />
                    </a>
                  )}
                </div>
              </section>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div className='empty-page'></div>
        )}
      </div>
    );
  }
}

export default withRouter((props) => <Home {...props} />);

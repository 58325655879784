import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import DesktopHeader from './desktopHeader';
import MobileHeader from './mobileHeader';

class Header extends React.Component {
  trackPagePreview = () => {
    const history = this.props;
    //defualt state for refresh or if visited from another website
    ReactGA.set({ page: history.location.pathname }); // Update the user's current page
    ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
    this.unlisten = this.props.history.listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
  };

  componentDidMount() {
    this.trackPagePreview();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <>
        <MobileHeader />
        <DesktopHeader />
      </>
    );
  }
}

export default withRouter((props) => <Header {...props} />);

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { slide as Menu } from 'react-burger-menu';

import api from './../../api/api';

import linkedInIcon from './../../assets/img/linked-in-icon.svg';
import whiteLogo from './../../assets/img/white-logo.svg';

class BurgerMenu extends React.Component {
  state = {
    menuOpen: false,
    data: '',
  };

  componentDidMount() {
    api.getOptions().then((response) => {
      this.setState({ data: response.data.acf });
    });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  handleMenuStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  clickOutsideSubscribe = () => {
    this.closeMenu();
  };

  render() {
    const { menuOpen, data } = this.state;
    return (
      <OutsideClickHandler onOutsideClick={this.clickOutsideSubscribe}>
        <Menu
          isOpen={menuOpen}
          onStateChange={(state) => this.handleMenuStateChange(state)}
          right
          disableAutoFocus
        >
          <img className='white-logo' src={whiteLogo} alt='white-logo' />
          <div className='bm-links'>
            <Link
              to='/'
              onClick={(e) => {
                this.closeMenu(e);
              }}
            >
              Home
            </Link>
            <Link
              to='/about'
              onClick={(e) => {
                this.closeMenu(e);
              }}
            >
              About us
            </Link>
            <Link
              to='/team'
              onClick={(e) => {
                this.closeMenu(e);
              }}
            >
              Team
            </Link>
            <Link
              to='/investments'
              onClick={(e) => {
                this.closeMenu(e);
              }}
            >
              Portfolio
            </Link>
            <Link
              to='/monsoon-tech'
              onClick={(e) => {
                this.closeMenu(e);
              }}
            >
              Monsoon Tech
            </Link>
            {/* <Link
              to='/jobs'
              onClick={(e) => {
                this.closeMenu(e);
              }}
            >
              Jobs
            </Link> */}
            <Link
              to='/contact'
              onClick={(e) => {
                this.closeMenu(e);
              }}
            >
              Contact
            </Link>
          </div>
          <a href={data.linkedin_url} className='linked-in-icon'>
            <img src={linkedInIcon} alt='linked-in-icon' />
          </a>
          <p className='bm-trademark'>
            © RazorCapital {(new Date().getFullYear())}. All Rights Reserved.
          </p>
        </Menu>
      </OutsideClickHandler>
    );
  }
}

export default withRouter((props) => <BurgerMenu {...props} />);

import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import navLeftArr from './../assets/img/popup-left-arr.svg';
import navRightArr from './../assets/img/popup-right-arr.svg';
import closeBtn from './../assets/img/popup-close-btn.svg';

import leftArrow from './../assets/img/contact-arrow-left.png';
import Footer from './footer';

class InvestmentPopUp extends React.Component {
  state = {
    index: this.props.index,
  };

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    const newSlug = this.props.data[this.state.index].slug;
    this.props.history.replace({ pathname: '/investments/' + newSlug });
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
    this.props.history.replace({ pathname: '/investments' });
  }

  createMarkup = (data) => {
    return { __html: data };
  };

  updateSlugInUrl = (index, data) => {
    const newSlug = data[index].slug;
    this.props.history.push(newSlug);
  };

  navigateForward = () => {
    const { data } = this.props;
    const { index } = this.state;
    const totalData = data.length;
    const newIndex = (index + 1) % totalData;
    this.setState({ index: newIndex }, () => {
      const { index } = this.state;
      this.updateSlugInUrl(index, data);
    });
  };

  navigateBackward = () => {
    const { data } = this.props;
    const { index } = this.state;
    const totalData = data.length;
    if (index === 0) {
      // reset the counter of indices
      this.setState({ index: totalData - 1 }, () => {
        const { index } = this.state;
        this.updateSlugInUrl(index, data);
      });
      return;
    }
    this.setState({ index: (index - 1) % totalData }, () => {
      const { index } = this.state;
      this.updateSlugInUrl(index, data);
    });
  };

  renderDetails = (data, type) => {
    return (
      data &&
      data.length &&
      data.map((item) => {
        return <p>{item[type + '_item']}</p>;
      })
    );
  };

  renderMobileSection = () => {
    const { data } = this.props;
    const { index } = this.state;
    const {
      acf: {
        company_featured_description,
        company_industry,
        company_milestone,
        company_founder,
        company_description,
        company_featured_image,
        company_logo_large,
        company_job_url,
      },
      title: { rendered: company_name },
    } = data[index];
    return (
      <section className='investment-popup-page investment-popup-page-mobile'>
        <div className='investment-popup-page-wrapper'>
          <div className='title'>
            <img src={company_logo_large} alt='logo' />
            <div className='navigation'>
              <div className='navigation-arrows'>
                <img
                  src={navLeftArr}
                  onClick={this.navigateBackward}
                  alt='left-arr'
                />
                <img
                  src={navRightArr}
                  onClick={this.navigateForward}
                  alt='right-arr'
                />
              </div>
              <img src={closeBtn} onClick={this.props.close} alt='close-btn' />
            </div>
          </div>
        </div>
        <section className='desc-section'>
          <section className='desc'>
            <div className='desc-img'>
              <img src={company_featured_image} alt='company-img' />
            </div>
            <div className='desc-wrapper'>
              <p className='desc-wrapper-name'>{company_name}</p>
              <h2
                className='desc-wrapper-title'
                dangerouslySetInnerHTML={this.createMarkup(
                  company_featured_description
                )}
              />
              <p
                className='desc-wrapper-text'
                dangerouslySetInnerHTML={this.createMarkup(company_description)}
              />
            </div>
            <div className='desc-details'>
              <div className='desc-details-item'>
                <h2>Industry</h2>
                {this.renderDetails(company_industry, 'company_industry')}
              </div>
              <div className='desc-details-item'>
                <h2>Milestones</h2>
                {this.renderDetails(company_milestone, 'company_milestone')}
              </div>
              <div className='desc-details-item'>
                <h2>Founders</h2>
                {this.renderDetails(company_founder, 'company_founder')}
              </div>
              <div className='desc-details-item'>
                <h2>Jobs</h2>
                {company_job_url.target === '' ? (
                  <p>
                    Discover
                    <Link to={company_job_url.url}>
                      <p>Opportunities at {company_name}</p>
                    </Link>
                  </p>
                ) : (
                  <p>
                    Discover &nbsp;
                    <a
                      href={company_job_url.url}
                      rel='noreferrer'
                      target={company_job_url.target}
                    >
                      <p>Opportunities at {company_name}</p>
                    </a>
                  </p>
                )}
              </div>
            </div>
          </section>
        </section>
        <section className='routes clear-spacing-mb'>
          <div>
            <Link to='/about' className='routes-about-btn'>
              <p>About us</p>
              <img src={leftArrow} alt='arrow' />
            </Link>
            <Link
              to='/investments'
              onClick={this.props.close}
              className='routes-investments-tech-btn'
            >
              <p>Portfolio</p>
              <img src={leftArrow} alt='arrow' />
            </Link>
          </div>
        </section>
        <Footer />
      </section>
    );
  };

  renderDesktopSection = () => {
    const { data } = this.props;
    const { index } = this.state;
    const {
      acf: {
        company_featured_description,
        company_industry,
        company_milestone,
        company_founder,
        company_description,
        company_featured_image,
        company_logo_large,
        company_job_url,
      },
      title: { rendered: company_name },
    } = data[index];
    return (
      <section className='investment-popup-page investment-popup-page-desktop'>
        <div className='investment-popup-page-wrapper'>
          <div className='title'>
            <img src={company_logo_large} alt='logo' />
            <div className='navigation'>
              <div className='navigation-arrows'>
                <img
                  src={navLeftArr}
                  onClick={this.navigateBackward}
                  alt='left-arr'
                />
                <img
                  src={navRightArr}
                  onClick={this.navigateForward}
                  alt='right-arr'
                />
              </div>
              <img src={closeBtn} onClick={this.props.close} alt='close-btn' />
            </div>
          </div>
        </div>
        <section className='desc-section'>
          <div className='desc'>
            <div className='desc-img'>
              <img src={company_featured_image} alt='company-img' />
            </div>
            <div className='desc-wrapper'>
              <p className='desc-wrapper-name'>{company_name}</p>
              <h2
                className='desc-wrapper-title'
                dangerouslySetInnerHTML={this.createMarkup(
                  company_featured_description
                )}
              />
              <p
                className='desc-wrapper-text'
                dangerouslySetInnerHTML={this.createMarkup(company_description)}
              />
            </div>
          </div>
          <div className='desc-details'>
            <div className='desc-details-item industry'>
              <h2>Industry</h2>
              {this.renderDetails(company_industry, 'company_industry')}
            </div>
            <div className='desc-details-item founders'>
              <h2>Founders</h2>
              {this.renderDetails(company_founder, 'company_founder')}
            </div>
            <div className='desc-details-item milestones'>
              <h2>Milestones</h2>
              {this.renderDetails(company_milestone, 'company_milestone')}
            </div>
            <div className='desc-details-item jobs'>
              <h2>Jobs</h2>
              {company_job_url.target === '' ? (
                <p>
                  Discover
                  <Link to={company_job_url.url}>
                    <p>Opportunities at {company_name}</p>
                  </Link>
                </p>
              ) : (
                <p>
                  Discover &nbsp;
                  <a
                    href={company_job_url.url}
                    rel='noreferrer'
                    target={company_job_url.target}
                  >
                    <p>Opportunities at {company_name}</p>
                  </a>
                </p>
              )}
            </div>
          </div>
        </section>
        <section className='routes clear-spacing-mb'>
          <div>
            <Link
              to='/investments'
              onClick={this.props.close}
              className='routes-about-btn'
            >
              <p>View Portfolio</p>
              <img src={leftArrow} alt='arrow' />
            </Link>
          </div>
        </section>
        <Footer />
      </section>
    );
  };

  render() {
    return (
      <>
        {this.renderMobileSection()} {this.renderDesktopSection()}
      </>
    );
  }
}

export default withRouter((props) => <InvestmentPopUp {...props} />);

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import BurgerMenu from './burgerMenu';
import darkLogo from './../../assets/img/dark-logo.svg';

class MobileHeader extends React.Component {
  componentDidMount() {
    document.title = 'Home / Razor Capital';
    this.listenerToUrlChange();
  }

  listenerToUrlChange = () => {
    const { location } = this.props.history;

    if (location.pathname === '/') {
      document.body.classList.add('orange-header');
    } else {
      document.body.classList.remove('orange-header');
    }

    this.unlisten = this.props.history.listen((location) => {
      if (location.pathname === '/') {
        document.body.classList.add('orange-header');
      } else {
        document.body.classList.remove('orange-header');
      }
    });
  };

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <header className='mobile-header'>
        <div className='mobile-header-wrapper'>
          <Link to='/'>
            <img src={darkLogo} alt='logo' />
          </Link>

          <div className='burger-menu'>
            <BurgerMenu />
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter((props) => <MobileHeader {...props} />);

import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import navLeftArr from './../assets/img/popup-left-arr.svg';
import navRightArr from './../assets/img/popup-right-arr.svg';
import closeBtn from './../assets/img/popup-close-btn.svg';
import linkedin from './../assets/img/linkedin.svg';
import leftArrow from './../assets/img/contact-arrow-left.png';
import Footer from './footer';

class TeamPopUp extends React.Component {
  state = {
    index: this.props.index,
  };

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    const newSlug = this.props.data[this.state.index].slug;
    this.props.history.replace({ pathname: '/team/' + newSlug });
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
    this.props.history.replace({ pathname: '/team' });
  }

  createMarkup = (data) => {
    return { __html: data };
  };

  updateSlugInUrl = (index, data) => {
    const newSlug = data[index].slug;
    this.props.history.push(newSlug);
  };

  navigateForward = () => {
    const { data } = this.props;
    const { index } = this.state;
    const totalData = data.length;
    const newIndex = (index + 1) % totalData;
    this.setState({ index: newIndex }, () => {
      const { index } = this.state;
      this.updateSlugInUrl(index, data);
    });
  };

  navigateBackward = () => {
    const { data } = this.props;
    const { index } = this.state;
    const totalData = data.length;
    if (index === 0) {
      // reset the counter of indices
      this.setState({ index: totalData - 1 }, () => {
        const { index } = this.state;
        this.updateSlugInUrl(index, data);
      });
      return;
    }
    this.setState({ index: (index - 1) % totalData }, () => {
      const { index } = this.state;
      this.updateSlugInUrl(index, data);
    });
  };

  renderDetails = (data, type) => {
    return (
      data &&
      data.length &&
      data.map((item) => {
        return <p>{item[type + '_item']}</p>;
      })
    );
  };

  renderPopupSection = () => {
    const { data } = this.props;
    const { index } = this.state;
    const {
      acf: {
        team_member_role,
        team_member_type,
        team_member_linkedin_url,
        team_member_personal_title,
        team_member_personal_description,
        team_member_main_image,
      },
      title: { rendered: name },
      content: { rendered: desc_uppper },
    } = data[index];
    const noImgClass =
      team_member_type === 'executive-partners' ? '' : 'popup-with-no-image';
    return (
      <div className={'team-popup-page ' + noImgClass}>
        <div className='desc-section'>
          <div className='desc'>
            <div class='desc-section-upper'>
              <div class='desc-section-upper-content'>
                {!team_member_main_image ? (
                  <></>
                ) : (
                  <section className='desc-img'>
                    <div>
                      {data.map((member, i) => {
                        console.log(member);
                        return member.acf.team_member_main_image ? (
                          <img
                            src={member.acf.team_member_main_image}
                            style={
                              !(i === index)
                                ? { display: 'none' }
                                : { display: 'block' }
                            }
                            alt='company-img'
                          />
                        ) : (
                          <></>
                        );
                      })}
                    </div>
                  </section>
                )}
                <section className='desc-wrapper'>
                  <div className='desc-wrapper-navigation'>
                    <div className='desc-wrapper-navigation-arrows'>
                      <img
                        src={navLeftArr}
                        onClick={this.navigateBackward}
                        alt='left-arr'
                      />
                      <img
                        src={navRightArr}
                        onClick={this.navigateForward}
                        alt='right-arr'
                      />
                    </div>
                    <img
                      src={closeBtn}
                      onClick={this.props.close}
                      alt='close-btn'
                    />
                  </div>
                  <div className='desc-wrapper-content'>
                    <h1
                      className='desc-wrapper-content-name'
                      dangerouslySetInnerHTML={this.createMarkup(name)}
                    />
                    <span
                      className='desc-wrapper-content-role'
                      dangerouslySetInnerHTML={this.createMarkup(
                        team_member_role
                      )}
                    />
                    <a
                      className='social-media-link'
                      href={team_member_linkedin_url}
                    >
                      <img
                        className='desc-wrapper-content-socialmedia'
                        src={linkedin}
                        alt={name}
                      />
                    </a>
                    <p
                      className='desc-wrapper-content-desc'
                      dangerouslySetInnerHTML={this.createMarkup(desc_uppper)}
                    />
                  </div>
                </section>
              </div>
            </div>
            {!(
              team_member_personal_title && team_member_personal_description
            ) ? (
              <></>
            ) : (
              <section class='desc-wrapper-down-wrapper'>
                <span class='border-seperation'>&nbsp;</span>
                <div className='desc-wrapper-down'>
                  <div className='desc-wrapper-down-wrapper'>
                    <h2 className='desc-wrapper-down-title'>
                      {team_member_personal_title}
                    </h2>
                    <p className='desc-wrapper-down-content'>
                      <p
                        dangerouslySetInnerHTML={this.createMarkup(
                          team_member_personal_description
                        )}
                      />
                    </p>
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
        <section className='routes clear-spacing-mb'>
          <div>
            <Link to='/about' className='routes-about-btn'>
              <p>About us</p>
              <img src={leftArrow} alt='arrow' />
            </Link>
            <Link
              to='/monsoon-tech'
              onClick={this.props.close}
              className='routes-monsoon-tech-btn'
            >
              <p>Monsoon</p>
              <img src={leftArrow} alt='arrow' />
            </Link>
          </div>
        </section>
        <Footer />
      </div>
    );
  };

  render() {
    return <>{this.renderPopupSection()}</>;
  }
}

export default withRouter((props) => <TeamPopUp {...props} />);

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Api from '../api/api.js';
import leftArrow from './../assets/img/home-head-arrow.png';
import moment from 'moment';

import darkLogo from './../assets/img/dark-logo.svg';

const moveLoadMoreBy = window.innerWidth < 941 ? 2 : 4;
const defaultLoadMoreSize = window.innerWidth < 941 ? 4 : 6;

class Monsoon extends React.Component {
  state = {
    data: '',
    newsData: '',
    teamData: '',
    latestNewsLoaded: defaultLoadMoreSize,
    isLoadMoreVisible: true,
  };

  componentDidMount() {
    document.title = 'Monsoon / Razor Capital';

    Api.getMonsoonsData().then((response) =>
      this.setState({ data: response.data[0].acf })
    );

    Api.getTeam().then((response) =>
      this.setState({ teamData: response.data.acf })
    );

    Api.getLatestNews().then((response) =>
      this.setState({ newsData: response.data })
    );
  }

  loadMoreNews = (total) => {
    this.setState(
      { latestNewsLoaded: this.state.latestNewsLoaded + moveLoadMoreBy },
      () => {
        if (!(total - this.state.latestNewsLoaded > 0)) {
          this.setState({ isLoadMoreVisible: false });
        }
      }
    );
  };

  renderLatestNews = (data) => {
    const { latestNewsLoaded, isLoadMoreVisible } = this.state;
    // console.log(defaultLoadMoreSize);
    return (
      <section className='latest-news-section'>
        <span className='latest-news-section-heading'>
          <h2>Bangladesh</h2>
          <h1>In the news</h1>
        </span>
        <div className='latest-news-section-container'>
          {data.slice(0, latestNewsLoaded).map((item, i) => {
            if (item.acf.adventure === 'monsoonTech') {
              const {
                title: { rendered: desc },
                acf: {
                  news_image: { url: imgUrl },
                  read_time,
                  external_link: { url: newsUrl },
                },
                date_gmt,
              } = item;
              const dateFormat = moment(date_gmt).format('MMMM D, YYYY');
              const readTime = read_time ? (
                <>&nbsp;&nbsp;-&nbsp;&nbsp;{read_time} read</>
              ) : (
                ''
              );
              return (
                <a
                  className='latest-news-section-wrapper'
                  key={'latest-news-section-wrapper-' + i}
                  href={newsUrl}
                  rel='noreferrer'
                  target='_blank'
                >
                  <img src={imgUrl} alt={desc} />
                  <div className='latest-news-section-content'>
                    <p className='latest-news-section-content-date'>
                      {dateFormat}
                      {readTime}
                    </p>
                    <p
                      className='latest-news-section-content-desc'
                      dangerouslySetInnerHTML={this.createMarkup(desc)}
                    />
                  </div>
                </a>
              );
            } else {
              return '';
            }
          })}
        </div>
        {!isLoadMoreVisible || data.length <= defaultLoadMoreSize ? (
          <></>
        ) : (
          <div className='load-more-content'>
            <div
              className='load-more-content-overlay cursor-pointer'
              onClick={() => this.loadMoreNews(data.length)}
            />
            <span>Load more</span>
          </div>
        )}
      </section>
    );
  };

  createMarkup = (data) => {
    return { __html: data };
  };

  renderHeadSection = (data) => {
    const bgImage =
      window.innerWidth > 960
        ? data.hero_image_desktop
        : data.hero_image_mobile;
    return (
      <div className='monsoon-page-head'>
        <div
          className='monsoon-page-head-wrapper'
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className='monsoon-page-head-wrapper-text mobile-section-width'>
            <span
              dangerouslySetInnerHTML={this.createMarkup(data.hero_title)}
            />
            <span
              dangerouslySetInnerHTML={this.createMarkup(data.hero_paragraph)}
            />
          </div>
        </div>
        <div className='in-the-press'>
          {window.innerWidth > 960 ? (
            <>
              <div className='in-the-press-logos-wrapper'>
                {data.in_the_press.map((item) => {
                  return (
                    <div className='in-the-press-logos'>
                      <img src={item.image} alt='logo' />
                    </div>
                  );
                })}
              </div>
              <div className='in-the-press-content-wrapper'>
                {data.in_the_press.map((item) => {
                  return (
                    <div className='in-the-press-content'>
                      <span
                        dangerouslySetInnerHTML={this.createMarkup(item.quote)}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              {data.in_the_press.map((item, i) => {
                return i % 2 === 0 ? (
                  <div className={`in-the-press-wrapper`}>
                    <div
                      className={`mobile-section-width in-the-press-wrapper-images`}
                    >
                      <img src={item.image} alt='logo' />
                      {i + 1 < data.in_the_press.length ? (
                        <img src={data.in_the_press[i + 1].image} alt='logo' />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className={`in-the-press-wrapper-content`}>
                      <div className={`mobile-section-width`}>
                        <span
                          dangerouslySetInnerHTML={this.createMarkup(
                            item.quote
                          )}
                        />
                        {i + 1 < data.in_the_press.length ? (
                          <span
                            dangerouslySetInnerHTML={this.createMarkup(
                              item.quote
                            )}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                );
              })}
            </>
          )}
        </div>
        <div className='section-1'>
          <p
            className='mobile-section-width section-1-title'
            dangerouslySetInnerHTML={this.createMarkup(data.section_1_title)}
          />
          <div className='mobile-section-width section-1-content'>
            {data.section_1_content.map(({ title, paragraph }) => {
              return (
                <div className='section-1-content-item'>
                  <h2 className='section-1-content-item-title'>{title}</h2>
                  <p
                    className='section-1-content-item-paragraph'
                    dangerouslySetInnerHTML={this.createMarkup(paragraph)}
                  />
                </div>
              );
            })}
          </div>
          <div className='section-1-image'>
            <img
              className='section-1-image-desktop'
              alt={data.section_1_title}
              src={data.section_1_image_desktop}
            />
            <img
              className='section-1-image-mobile'
              alt={data.section_1_title}
              src={data.section_1_image_mobile}
            />
          </div>
        </div>
        <div class='section-2'>
          <p
            className='section-2-title'
            dangerouslySetInnerHTML={this.createMarkup(data.section_2_title)}
          />
          <p className='section-2-number'>
            <span>1</span>
          </p>
          <div className='section-2-wrapper'>
            <div className='section-2-wrapper-one-title mobile-section-width'>
              <span
                dangerouslySetInnerHTML={this.createMarkup(
                  data.section_2_economic_growth_title
                )}
              />
              <p className='subtitle'>
                {data.section_2_economic_growth_subtitle}
              </p>
              <p
                className='desc'
                dangerouslySetInnerHTML={this.createMarkup(
                  data.section_2_economic_growth_description
                )}
              />
            </div>
            <img
              className='section-2-image-desktop'
              src={data.section_2_economic_growth_image_desktop}
              alt='graph'
            />
            <img
              className='section-2-image-mobile'
              src={data.section_2_economic_growth_image_mobile}
              alt='graph'
            />
            <div className='section-2-economic-growth'>
              <div className='graph-one'>
                <img
                  className='graph-one-image-desktop'
                  src={data.section_2_economic_growth_table_desktop}
                  alt='graph'
                />
                <img
                  className='graph-one-image-mobile'
                  src={data.section_2_economic_growth_table_mobile}
                  alt='graph'
                />
              </div>
              <div className='graph-two'>
                <img
                  className='graph-two-image-desktop'
                  src={data.section_2_economic_growth_image_2_desktop}
                  alt='graph'
                />
                <img
                  className='graph-two-image-mobile'
                  src={data.section_2_economic_growth_image_2_mobile}
                  alt='graph'
                />
              </div>
              <div className='graph-three'>
                <img
                  className='graph-three-image-desktop'
                  src={data.section_2_economic_growth_image_3_desktop}
                  alt='graph'
                />
                <img
                  className='graph-three-image-mobile'
                  src={data.section_2_economic_growth_image_3_mobile}
                  alt='graph'
                />
              </div>
            </div>
            <div className='section-2-demographic-dividend'>
              <p className='section-2-demographic-dividend-number'>
                <span>2</span>
              </p>
              <div className='section-2-demographic-dividend-title mobile-section-width'>
                <span
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_2_demographic_dividend_title
                  )}
                />
                <span>
                  <p className='subtitle'>
                    {data.section_2_demographic_dividend_subtitle}
                  </p>
                  <p
                    className='desc'
                    dangerouslySetInnerHTML={this.createMarkup(
                      data.section_2_demographic_dividend_description
                    )}
                  />
                </span>
              </div>
              <img
                className='section-2-demographic-dividend-image-desktop'
                src={data.section_2_demographic_dividend_image_desktop}
                alt='active-workspace'
              />
              <img
                className='section-2-demographic-dividend-image-mobile'
                src={data.section_2_demographic_dividend_image_mobile}
                alt='active-workspace'
              />
              <div className='section-2-demographic-dividend-economic-effect'>
                <div className='section-2-demographic-dividend-economic-effect-wrapper mobile-section-width'>
                  <span
                    dangerouslySetInnerHTML={this.createMarkup(
                      data.section_2_demographic_dividend_positive_economic_effect_title
                    )}
                  />
                  <p
                    dangerouslySetInnerHTML={this.createMarkup(
                      data.section_2_demographic_dividend_positive_economic_effect_description
                    )}
                  />
                  <img
                    className='section-2-demographic-dividend-economic-effect-image-desktop'
                    src={
                      data.section_2_demographic_dividend_positive_economic_effect_image_desktop
                    }
                    alt='active-workspace'
                  />
                  <img
                    className='section-2-demographic-dividend-economic-effect-image-mobile'
                    src={
                      data.section_2_demographic_dividend_positive_economic_effect_image_mobile
                    }
                    alt='active-workspace'
                  />
                </div>
              </div>
              <img
                className='section-2-demographic-dividend-potent-business-image-desktop '
                src={
                  data.section_2_demographic_dividend_potent_business_image_desktop
                }
                alt='active-workspace'
              />
              <img
                className='section-2-demographic-dividend-potent-business-image-mobile mobile-section-width'
                src={
                  data.section_2_demographic_dividend_potent_business_image_mobile
                }
                alt='active-workspace'
              />
            </div>
            <div
              className='section-2-education'
              style={
                window.innerWidth > 940
                  ? {
                      backgroundImage: `url(${data.section_2_quality_education_image_1_desktop})`,
                    }
                  : { backgroundImage: `` }
              }
            >
              <p className='section-2-education-number'>
                <span>3</span>
              </p>
              <div className='section-2-education-title mobile-section-width'>
                <span
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_2_quality_education_title
                  )}
                />
                <p className='subtitle'>
                  {data.section_2_quality_education_subtitle}
                </p>
                <p
                  className='desc'
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_2_quality_education_description
                  )}
                />
              </div>
              <img
                className='section-2-education-image-mobile'
                src={data.section_2_quality_education_image_1_mobile}
                alt='active-workspace'
              />
              <img
                className='section-2-education-image-desktop '
                src={data.section_2_quality_education_image_2_desktop}
                alt='active-workspace'
              />
              <img
                className='section-2-education-image-mobile'
                src={data.section_2_quality_education_image_2_mobile}
                alt='active-workspace'
              />
            </div>
            <div className='section-2-skilled-workforce'>
              <p className='section-2-skilled-workforce-number'>
                <span>4</span>
              </p>
              <div className='section-2-skilled-workforce-title mobile-section-width'>
                <span
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_2_skilled_workforce_title
                  )}
                />
                <p className='subtitle'>
                  {data.section_2_skilled_workforce_subtitle}
                </p>
                <p
                  className='desc'
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_2_skilled_workforce_description_1
                  )}
                />
              </div>
              <span>
                <img
                  className='section-2-skilled-workforce-image-desktop '
                  src={data.section_2_skilled_workforce_image_desktop}
                  alt='hand-shake'
                />
                <img
                  className='section-2-skilled-workforce-image-mobile'
                  src={data.section_2_skilled_workforce_image_mobile}
                  alt='hand-shake'
                />
                <div className='section-2-skilled-workforce-desc2 mobile-section-width'>
                  <p
                    className='desc-wrapper'
                    dangerouslySetInnerHTML={this.createMarkup(
                      data.section_2_skilled_workforce_description_2
                    )}
                  />
                </div>
              </span>
            </div>
            <div
              className='section-2-strategically-located'
              style={
                window.innerWidth > 940
                  ? {
                      backgroundImage: `url(${data.section_2_strategically_located_image_1_desktop})`,
                    }
                  : {}
              }
            >
              <p className='section-2-strategically-located-number'>
                <span>5</span>
              </p>
              <div className='section-2-strategically-located-title mobile-section-width'>
                <span
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_2_strategically_located_title
                  )}
                />
                <p className='subtitle'>
                  {data.section_2_strategically_located_subtitle}
                </p>
                <p
                  className='desc'
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_2_strategically_located_description_1
                  )}
                />
              </div>
              <img
                className='section-2-strategically-located-image-mobile'
                src={data.section_2_strategically_located_image_1_mobile}
                alt='world-img'
              />
              <div className='section-2-strategically-located-desc2 mobile-section-width'>
                <p
                  className='desc-wrapper'
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_2_strategically_located_description_2
                  )}
                />
              </div>
              <div className='section-2-strategically-located-image mobile-section-width'>
                <img
                  src={data.section_2_strategically_located_flags}
                  alt='flags-img'
                />
              </div>
            </div>
            <div
              className='section-2-bip'
              style={
                window.innerWidth > 940
                  ? {
                      backgroundImage: `url(${data.section_2_bip_image_1_desktop})`,
                    }
                  : {}
              }
            >
              <p className='section-2-bip-number'>
                <span>6</span>
              </p>
              <div className='section-2-bip-title mobile-section-width'>
                <span
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_2_bip_title
                  )}
                />
                <p className='subtitle'>{data.section_2_bip_subtitle}</p>
                <p
                  className='desc'
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_2_bip_description_1
                  )}
                />
              </div>
              <img
                className='section-2-bip-image-mobile'
                src={data.section_2_bip_image_1_mobile}
                alt='world-img'
              />
              <div className='section-2-bip-desc2 mobile-section-width'>
                <p
                  className='desc-wrapper'
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_2_bip_description_2
                  )}
                />
              </div>
              {window.innerWidth > 960 ? (
                <>
                  <div
                    className='section-2-bip-number-image'
                    style={{
                      backgroundImage: `url(${data.section_2_bip_image_2_desktop})`,
                    }}
                  >
                    <p
                      className='section-2-bip-number-image-desc-desktop'
                      dangerouslySetInnerHTML={this.createMarkup(
                        data.section_2_bip_description_4
                      )}
                    />
                  </div>
                  <div className='section-2-bip-three-sections mobile-section-width'>
                    {data.section_2_bip_paragraphs.map((item, i) => {
                      return i !== 3 ? (
                        <div className='section-2-bip-three-sections-item'>
                          <div className='section-2-bip-three-sections-item-title'>
                            <img src={item.icon} alt='icon' />
                            <h3>
                              <span>{item.title}</span>
                            </h3>
                          </div>
                          <p
                            className='section-2-bip-three-sections-item-desc'
                            dangerouslySetInnerHTML={this.createMarkup(
                              item.description
                            )}
                          />
                        </div>
                      ) : (
                        <div className='section-2-bip-three-sections-item-full'>
                          <div className='section-2-bip-three-sections-item-full-image'>
                            <img src={item.icon} alt='icon' />
                          </div>
                          <div className='section-2-bip-three-sections-item-full-desc'>
                            <h3>
                              <span>{item.title}</span>
                            </h3>
                            <p
                              className='text'
                              dangerouslySetInnerHTML={this.createMarkup(
                                item.description
                              )}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div className='section-2-bip-number-image'>
                    <img
                      className='section-2-bip-number-image-mobile'
                      src={data.section_2_bip_image_2_mobile}
                      alt='number-slide'
                    />
                  </div>
                  <div className='section-2-bip-number-text mobile-section-width'>
                    <p
                      className='section-2-bip-number-text-desc'
                      dangerouslySetInnerHTML={this.createMarkup(
                        data.section_2_bip_description_4
                      )}
                    />
                  </div>
                  <div className='section-2-bip-three-sections mobile-section-width'>
                    {data.section_2_bip_paragraphs.map((item, i) => {
                      return (
                        <div className='section-2-bip-three-sections-item'>
                          <div className='section-2-bip-three-sections-item-title'>
                            <img src={item.icon} alt='icon' />
                            <h3>
                              <span>{item.title}</span>
                            </h3>
                          </div>
                          <p
                            className='section-2-bip-three-sections-item-desc'
                            dangerouslySetInnerHTML={this.createMarkup(
                              item.description
                            )}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='section-3'>
          <div className='section-3-title'>
            <span
              dangerouslySetInnerHTML={this.createMarkup(data.section_3_title)}
            />
          </div>
          <div className='section-3-content desktop-section-width'>
            {data.section_3_content.map((item, i) => {
              return (
                <div
                  key={'desc-' + i}
                  className='section-3-content-wrapper mobile-section-width'
                >
                  <p className='number'>{i + 1}.</p>
                  <span
                    dangerouslySetInnerHTML={this.createMarkup(
                      item.description
                    )}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className='section-4'>
          <div className='section-4-title'>
            <span
              dangerouslySetInnerHTML={this.createMarkup(data.section_4_title)}
            />
          </div>
          <div className='section-4-content desktop-section-width'>
            {data.section_4_content.map((item, i) => {
              return (
                <div
                  key={'desc-' + i}
                  className='section-4-content-wrapper mobile-section-width'
                >
                  <p className='number'>{i + 1}.</p>
                  <h1 dangerouslySetInnerHTML={this.createMarkup(item.title)} />
                  <span
                    dangerouslySetInnerHTML={this.createMarkup(
                      item.description
                    )}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  renderFooterLinks = () => {
    return (
      <div className='monsoon-footer-links'>
        <div className='monsoon-footer-links-menu left'>
          <p className='title'>Explore</p>
          <Link to='/team' className='menu-item'>
            <p>
              <span>TEAM</span>
            </p>
          </Link>
          <Link to='/investments' className='menu-item'>
            <p>
              <span>PORTFOLIO</span>
            </p>
          </Link>
          <Link to='/monsoon-tech' className='menu-item'>
            <p>
              <span>MONSOON TECH</span>
            </p>
          </Link>
        </div>
        <div className='monsoon-footer-links-menu right'>
          <p className='title'>Information</p>
          <Link to='/about' className='menu-item'>
            <p>
              <span>ABOUT</span>
            </p>
          </Link>
          <Link to='/contact' className='menu-item'>
            <p>
              <span>CONTACT</span>
            </p>
          </Link>
          {/* <Link to='/jobs' className='menu-item'>
            <p>
              <span>JOBS</span>
            </p>
          </Link> */}
        </div>
      </div>
    );
  };

  renderFooter() {
    return (
      <footer className='monsoon-footer'>
        <div className='monsoon-footer-wrapper'>
          <div className='monsoon-footer-contact'>
            <p className='monsoon-name'>Monsoon Tech</p>
          </div>
          {this.renderFooterLinks()}
          <Link to='/'>
            <img src={darkLogo} alt='logo' />
          </Link>
        </div>
        <p className='trademark'>© RazorCapital {(new Date().getFullYear())}. All Rights Reserved.</p>
      </footer>
    );
  }

  render() {
    const { data, newsData, teamData } = this.state;
    return (
      <div className='monsoon-page'>
        {data ? (
          <>
            {this.renderHeadSection(data)}
            {/* {newsData ? this.renderLatestNews(newsData) : <></>} */}
            {teamData ? (
              <section className='team-section clear-spacing-mb clear-spacing-ds'>
                <div
                  className='team-section-wrapper'
                  style={
                    window.innerWidth > 940
                      ? {
                          backgroundImage: `url(${teamData.footer_section_image})`,
                        }
                      : {
                          backgroundImage: `url(${teamData.footer_section_image_mobile})`,
                        }
                  }
                >
                  <span
                    dangerouslySetInnerHTML={this.createMarkup(
                      teamData.footer_section_title
                    )}
                  />
                  {teamData.footer_section_call_to_action.target === '' ? (
                    <Link to={teamData.footer_section_call_to_action.url}>
                      <p>{teamData.footer_section_call_to_action.title}</p>
                      <img src={leftArrow} alt='arrow' />
                    </Link>
                  ) : (
                    <a
                      href={teamData.footer_section_call_to_action.url}
                      rel='noreferrer'
                      target={teamData.footer_section_call_to_action.target}
                    >
                      <p>{teamData.footer_section_call_to_action.title}</p>
                      <img src={leftArrow} alt='arrow' />
                    </a>
                  )}
                </div>
              </section>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div className='full-height'></div>
        )}
        {this.renderFooter()}
      </div>
    );
  }
}

export default withRouter((props) => <Monsoon {...props} />);

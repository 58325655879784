import Home from './views/home';
import Contact from './views/contact';
import Jobs from './views/jobs';
import About from './views/about';
import Investments from './views/investments';
import Team from './views/team';
import Monsoon from './views/monsoon';

const routes = [
  {
    path: '/team',
    component: Team,
    exact: false,
  },
  {
    path: '/investments',
    component: Investments,
    exact: false,
  },
  {
    path: '/monsoon-tech',
    component: Monsoon,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/jobs',
    component: Jobs,
  },
  {
    path: '/contact',
    component: Contact,
  },
  {
    path: '/',
    component: Home,
  },
];

export default routes;

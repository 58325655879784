import './App.scss';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';

import routes from './routes';
import Footer from './components/footer';
import Header from './components/header/header';
import ScrollToTop from './components/scrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        {routes.map((route, i) => {
          const { path } = route;
          const Comp = route.component;
          if (route.hasOwnProperty('exact') && route.exact === false) {
            return (
              <Route path={path} key={path + '-' + i} render={() => <Comp />} />
            );
          }
          return (
            <Route
              exact
              path={path}
              key={path + '-' + i}
              render={() => <Comp />}
            />
          );
        })}
        <Redirect to='/' />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;

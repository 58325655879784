import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import darkLogo from './../assets/img/dark-logo.svg';
import footerArrow from './../assets/img/footer-arrow.svg';

class Footer extends React.Component {
  componentDidMount() {}

  renderFooterLinks = () => {
    return (
      <div className='footer-links'>
        <div className='footer-links-menu left'>
          <p className='title'>Explore</p>
          <Link to='/team' className='menu-item'>
            <p>
              <span>TEAM</span>
            </p>
          </Link>
          <Link to='/investments' className='menu-item'>
            <p>
              <span>PORTFOLIO</span>
            </p>
          </Link>
          <Link to='/monsoon-tech' className='menu-item'>
            <p>
              <span>MONSOON TECH</span>
            </p>
          </Link>
        </div>
        <div className='footer-links-menu right'>
          <p className='title'>Information</p>
          <Link to='/about' className='menu-item'>
            <p>
              <span>ABOUT</span>
            </p>
          </Link>
          <Link to='/contact' className='menu-item'>
            <p>
              <span>CONTACT</span>
            </p>
          </Link>
          {/* <Link to="/jobs" className="menu-item"><p><span>JOBS</span></p></Link> */}
        </div>
      </div>
    );
  };

  render() {
    return (
      <footer className='footer'>
        <div className='footer-wrapper'>
          <div className='footer-contact'>
            <p>Looking for more answers?</p>
            <Link to='/contact' className='footer-contact-cta'>
              <h1>Get in touch</h1>
              <img src={footerArrow} alt='right-arrow' />
            </Link>
          </div>
          {this.renderFooterLinks()}
          <Link to='/'>
            <img src={darkLogo} alt='logo' />
          </Link>
        </div>
        <p className='trademark'>© RazorCapital {(new Date().getFullYear())}. All Rights Reserved.</p>
      </footer>
    );
  }
}

export default withRouter((props) => <Footer {...props} />);

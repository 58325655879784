import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Api from '../api/api.js';
import InvestmentPopUp from '../components/investmentPopUp.js';

import leftArrow from './../assets/img/contact-arrow-left.png';
import dropArrow from './../assets/img/dropdown-arr.svg';

class Investments extends React.Component {
  state = {
    data: '',
    companyCounter: '',
    titleData: '',
    filterType: 'all', // default is show all
    filterStage: 'all',
    filterLocation: 'all',
    filterIndustry: 'all',
    filterOpen: false,
    investmentPopUpIndex: null,
  };

  isSlugValid = (slug, data) => {
    let index = null;
    data.forEach((item, i) => {
      if (item.slug === slug) {
        index = i;
      }
    });
    return index;
  };

  openPopupGivenSlug = () => {
    const {
      location: { pathname },
    } = this.props;
    const pathNameSplit = pathname.split('/investments/');
    const slug = pathNameSplit[1];
    const { data } = this.state;
    if (slug) {
      const slugIndex = this.isSlugValid(slug, data);
      if (slugIndex !== null) {
        this.setState({ investmentPopUpIndex: slugIndex });
      }
    }
    return false;
  };

  componentDidMount() {
    document.title = 'Portfolio / Razor Capital';
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    Api.getCompanies().then((response) =>
      this.setState({ data: response.data }, this.openPopupGivenSlug)
    );

    Api.getInvestments().then((response) =>
      this.setState({ titleData: response.data[0].acf })
    );
  }

  createMarkup = (data) => {
    return { __html: data };
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth }, () => {
      if (this.state.windowWidth < 941) {
        this.setState({ companyCounter: 4 });
      } else if (this.state.windowWidth < 1720) {
        this.setState({ companyCounter: 5 });
      } else {
        this.setState({ companyCounter: 6 });
      }
    });
  };

  renderCompanies = (data) => {
    const {
      companyCounter,
      filterType,
      filterStage,
      filterLocation,
      filterIndustry,
    } = this.state;
    const width =
      companyCounter === 4
        ? '48.67vw'
        : companyCounter === 5
        ? 100 / companyCounter - 0.22 + 'vw'
        : 100 / companyCounter + 'vw';
    // const width = (companyCounter === 4)? "50vw" : (100/companyCounter) + "vw" ;
    return (
      <div className='company-section'>
        {data.map((company, i) => {
          const {
            acf: {
              company_status,
              company_logo,
              company_investment_year,
              company_featured_description,
              company_featured_image,
              company_industry,
              company_stage,
              company_location,
            },
            title: { rendered: titleRendered },
          } = company;
          const companyIndustry = company_industry.map(
            (d) => d.company_industry_item
          );
          const companyStage = company_stage.map((d) => d.company_stage_item);
          const companyLocation = company_location.map(
            (d) => d.company_location_item
          );
          return (
            <>
              {(filterType === 'all' || filterType === company_status) &&
              (filterStage === 'all' ||
                companyStage.indexOf(filterStage) > -1) &&
              (filterLocation === 'all' ||
                companyLocation.indexOf(filterLocation) > -1) &&
              (filterIndustry === 'all' ||
                companyIndustry.indexOf(filterIndustry) > -1) ? (
                <div
                  key={'company-section' + i}
                  item={i % data.length}
                  onClick={() => this.setState({ investmentPopUpIndex: i })}
                  className='company-section-item cursor-pointer'
                  style={{
                    backgroundImage: `url(${company_featured_image})`,
                    width,
                    height: width,
                  }}
                >
                  <img src={company_logo} alt={titleRendered} />
                  <div className='company-section-desc'>
                    <img src={company_logo} alt='logo' />
                    <p
                      className='company-section-desc-text'
                      dangerouslySetInnerHTML={this.createMarkup(
                        company_featured_description
                      )}
                    />
                    <p className='invest-date'>
                      Invested in {company_investment_year}
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          );
        })}
      </div>
    );
  };

  filterByType = () => {
    // const types = ['all', 'active', 'exited', 'passive'];
    const types = [''];
    const { filterType } = this.state;
    return (
      <div className='filter-type'>
        {types.map((type, i) => (
          <span
            className={`filter-type-item cursor-pointer ${
              filterType === type ? 'active' : ''
            }`}
            key={type + i}
            onClick={() => {
              this.setState({ filterType: type });
            }}
          >
            {type}
          </span>
        ))}
      </div>
    );
  };

  openFilter = (filterCriteria) => {
    const { filterOpen } = this.state;
    let openState;
    if (filterCriteria === filterOpen) {
      openState = false;
    } else {
      openState = filterCriteria;
    }
    this.setState({ filterOpen: openState });
  };

  filterByCriteria = (dataList, filterCriteria) => {
    const { ['filter' + filterCriteria]: filterBy, filterOpen } = this.state;
    return (
      <div className={`filter-dropdown filter-${filterCriteria}`}>
        <div
          className='filter-dropdown-title cursor-pointer'
          onClick={() => this.openFilter(filterCriteria)}
        >
          <img src={dropArrow} alt='arrow-down' />
          <p>{filterBy === 'all' ? filterCriteria : filterBy}</p>
        </div>
        {filterOpen === filterCriteria ? (
          <div className='filter-dropdown-items'>
            <div
              className='filter-dropdown-title cursor-pointer'
              onClick={() => this.openFilter(filterCriteria)}
            >
              <img src={dropArrow} alt='arrow-down' />
              <p>{filterBy === 'all' ? filterCriteria : filterBy}</p>
            </div>
            {dataList.map((filtertype, i) => (
              <span
                className={`filter-dropdown-item cursor-pointer ${
                  filterBy === filtertype ? 'active' : ''
                }`}
                key={filtertype + i}
                onClick={() => {
                  this.setState({
                    ['filter' + filterCriteria]: filtertype,
                    filterOpen: false,
                  });
                }}
              >
                {filtertype}
              </span>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  filterByDetails = (data) => {
    // const { stage_filter, location_filter, industry_filter } = data;
    const { location_filter, industry_filter } = data;
    // const stageFilters = [
    //   'all',
    //   ...stage_filter.map((d) => d.stage_filter_item),
    // ];
    const locationFilters = [
      'all',
      ...location_filter.map((d) => d.location_filter_item),
    ];
    const industryFilters = [
      'all',
      ...industry_filter.map((d) => d.industry_filter_item),
    ];
    return (
      <div className='filter-details'>
        {/* {this.filterByCriteria(stageFilters, 'Stage')} */}
        {this.filterByCriteria(locationFilters, 'Location')}
        {this.filterByCriteria(industryFilters, 'Industry')}
      </div>
    );
  };

  closeInvestmentPopup = () => {
    this.setState({ investmentPopUpIndex: null });
  };

  render() {
    const { data, titleData, investmentPopUpIndex } = this.state;
    return (
      <div className='investments-page'>
        {data && titleData ? (
          <>
            {investmentPopUpIndex === null ? (
              <></>
            ) : (
              <InvestmentPopUp
                key={'investment-pop-' + investmentPopUpIndex}
                index={investmentPopUpIndex}
                data={data}
                close={this.closeInvestmentPopup}
              />
            )}
            <div className='investments-page-title'>
              <h1
                dangerouslySetInnerHTML={this.createMarkup(
                  titleData.hero_title
                )}
              />
              <p
                dangerouslySetInnerHTML={this.createMarkup(
                  titleData.hero_description
                )}
              />
            </div>
            <section className='investments-page-filters'>
              <div className='investments-page-filters-wrapper'>
                {this.filterByDetails(titleData)}
                {this.filterByType()}
              </div>
            </section>
            <div className='investments-page-items'>
              {this.renderCompanies(data)}
            </div>
            <section className='routes clear-spacing-mb'>
              <div>
                <Link to='/about' className='routes-about-btn'>
                  <p>About us</p>
                  <img src={leftArrow} alt='arrow' />
                </Link>
                <Link to='/monsoon-tech' className='routes-monsoon-tech-btn'>
                  <p>Monsoon Tech</p>
                  <img src={leftArrow} alt='arrow' />
                </Link>
              </div>
            </section>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter((props) => <Investments {...props} />);

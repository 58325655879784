import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Api from '../api/api.js';

import leftArrow from './../assets/img/home-head-arrow.png';

class About extends React.Component {
  state = {
    data: '',
    teamData: '',
  };

  componentDidMount() {
    document.title = 'About us / Razor Capital';

    Api.getTeam().then((response) =>
      this.setState({ teamData: response.data.acf })
    );

    Api.getAbout().then((response) =>
      this.setState({ data: response.data[0].acf })
    );
  }

  renderThreeParagraphs = (title, pargData, dataNumber, number) => {
    return (
      <div className='three-parg'>
        <p className='number-top'>{number} .</p>
        <h2 dangerouslySetInnerHTML={this.createMarkup(title)} />
        <div className='three-parg-wrapper'>
          {pargData.map((parg, i) => {
            return (
              <div className='three-parg-wrapper-desc'>
                <span className='number'>{i + 1}.</span>
                <span
                  dangerouslySetInnerHTML={this.createMarkup(parg[dataNumber])}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  createMarkup = (data) => {
    return { __html: data };
  };

  render() {
    const { data, teamData } = this.state;
    const widthDesktop = window.innerWidth > 940 ? true : false;
    return (
      <div className='about-page'>
        {data && teamData ? (
          <>
            <section className='head-section'>
              <div className='head-section-wrapper'>
                <h1
                  dangerouslySetInnerHTML={this.createMarkup(data.hero_title)}
                />
                <p
                  className='top'
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.hero_subtitle
                  )}
                />
                <p
                  className='bottom'
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.hero_description
                  )}
                />
              </div>
            </section>
            <section className='razor-rule-section'>
              <div className='razor-rule-section-wrapper'>
                <h1
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_1_title
                  )}
                />
                <p
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_1_description
                  )}
                />
                {this.renderThreeParagraphs(
                  data.section_1_1_title,
                  data.section_1_1_paragraphs,
                  'section_1_1_paragraph',
                  '1'
                )}
              </div>
            </section>
            <section className='industry-section'>
              <div className='industry-section-wrapper'>
                <div className='industry-section-wrapper-top'>
                  {widthDesktop ? (
                    <img
                      src={data.section_1_2_image_desktop}
                      alt='industry-desc'
                    />
                  ) : (
                    <img
                      src={data.section_1_2_image_mobile}
                      alt='industry-desc'
                    />
                  )}
                  <p
                    dangerouslySetInnerHTML={this.createMarkup(
                      data.section_1_2_description
                    )}
                  />
                </div>
                {this.renderThreeParagraphs(
                  data.section_1_3_title,
                  data.section_1_3_paragraphs,
                  'section_1_3_paragraph',
                  '2'
                )}
              </div>
            </section>
            {!widthDesktop ? (
              <section className='frontier-markets-section'>
                <section
                  style={{
                    backgroundImage: `url(${data.section_2_image_mobile})`,
                  }}
                >
                  <div className='frontier-markets-section-wrapper'>
                    <div className='frontier-markets-section-wrapper-desc'>
                      <span
                        dangerouslySetInnerHTML={this.createMarkup(
                          data.section_2_title
                        )}
                      />
                      <span
                        dangerouslySetInnerHTML={this.createMarkup(
                          data.section_2_description
                        )}
                      />
                      <Link to={data.section_2_call_to_action.url}>
                        <p>{data.section_2_call_to_action.title}</p>
                        <img src={leftArrow} alt='arrow' />
                      </Link>
                      {widthDesktop ? (
                        <span
                          dangerouslySetInnerHTML={this.createMarkup(
                            data.section_2_more_text
                          )}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </section>
              </section>
            ) : (
              <section
                className='frontier-markets-section'
                style={{ backgroundImage: `url(${data.section_2_image})` }}
              >
                <section>
                  <div className='frontier-markets-section-wrapper'>
                    <div className='frontier-markets-section-wrapper-desc'>
                      <span
                        dangerouslySetInnerHTML={this.createMarkup(
                          data.section_2_title
                        )}
                      />
                      <span
                        dangerouslySetInnerHTML={this.createMarkup(
                          data.section_2_description
                        )}
                      />
                      <Link to={data.section_2_call_to_action.url}>
                        <p>{data.section_2_call_to_action.title}</p>
                        <img src={leftArrow} alt='arrow' />
                      </Link>
                      {widthDesktop ? (
                        <span
                          dangerouslySetInnerHTML={this.createMarkup(
                            data.section_2_more_text
                          )}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </section>
              </section>
            )}
            <section className='serve-section'>
              <div className='serve-section-wrapper'>
                <span
                  dangerouslySetInnerHTML={this.createMarkup(
                    data.section_3_title
                  )}
                />
                <div>
                  {data.section_3_paragraphs.map((parg, i) => {
                    return (
                      <div className='serve-section-wrapper-desc'>
                        <p>{i + 1} .</p>
                        <span
                          dangerouslySetInnerHTML={this.createMarkup(
                            parg.section_3_paragraph
                          )}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
            <section className='team-section clear-spacing-mb clear-spacing-ds'>
              <div
                className='team-section-wrapper'
                style={
                  window.innerWidth > 940
                    ? {
                        backgroundImage: `url(${teamData.footer_section_image})`,
                      }
                    : {
                        backgroundImage: `url(${teamData.footer_section_image_mobile})`,
                      }
                }
              >
                <span
                  dangerouslySetInnerHTML={this.createMarkup(
                    teamData.footer_section_title
                  )}
                />
                {teamData.footer_section_call_to_action.target === '' ? (
                  <Link to={teamData.footer_section_call_to_action.url}>
                    <p>{teamData.footer_section_call_to_action.title}</p>
                    <img src={leftArrow} alt='arrow' />
                  </Link>
                ) : (
                  <a
                    href={teamData.footer_section_call_to_action.url}
                    rel='noreferrer'
                    target={teamData.footer_section_call_to_action.target}
                  >
                    <p>{teamData.footer_section_call_to_action.title}</p>
                    <img src={leftArrow} alt='arrow' />
                  </a>
                )}
              </div>
            </section>
            <section className='routes clear-spacing-mb'>
              <div>
                <Link to='/investments' className='routes-investments-btn'>
                  <p>Portfolio</p>
                  <img src={leftArrow} alt='arrow' />
                </Link>
                <Link to='/monsoon-tech' className='routes-monsoon-tech-btn'>
                  <p>Monsoon Tech</p>
                  <img src={leftArrow} alt='arrow' />
                </Link>
              </div>
            </section>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter((props) => <About {...props} />);

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Api from '../api/api.js';

import leftArrow from './../assets/img/contact-arrow-left.png';

class Jobs extends React.Component {
  state = {
    data: '',
    companyCounter: '',
  };

  componentDidMount() {
    document.title = 'Jobs / Razor Capital';
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    Api.getCompanies().then((response) =>
      this.setState({ data: response.data })
    );
  }

  createMarkup = (data) => {
    return { __html: data };
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth }, () => {
      if (this.state.windowWidth < 941) {
        this.setState({ companyCounter: 4 });
      } else if (this.state.windowWidth < 1720) {
        this.setState({ companyCounter: 5 });
      } else {
        this.setState({ companyCounter: 6 });
      }
    });
  };

  renderCompanies = (data) => {
    const { companyCounter } = this.state;
    const width = companyCounter === 4 ? '50vw' : 100 / companyCounter + 'vw';
    return (
      <div className='company-section'>
        {data.map((company, i) => {
          return (
            <>
              {company.acf.show_on_jobs_page ? (
                <a
                  href={company.acf.company_job_url.url}
                  target={company.acf.company_job_url.target}
                  key={'company-section' + i}
                  className='company-section-item'
                  style={{
                    backgroundImage: `url(${company.acf.company_featured_image})`,
                    width,
                    height: width,
                  }}
                >
                  <img
                    src={company.acf.company_logo}
                    alt={company.title.rendered}
                  />
                  <div className='company-section-desc'>
                    <img src={company.acf.company_logo} alt='logo' />
                    <p
                      className='company-section-desc-text'
                      dangerouslySetInnerHTML={this.createMarkup(
                        company.acf.company_featured_description
                      )}
                    />
                    <p className='invest-date'>
                      Invested in {company.acf.company_investment_year}
                    </p>
                  </div>
                </a>
              ) : (
                <></>
              )}
            </>
          );
        })}
      </div>
    );
  };

  render() {
    const { data } = this.state;
    return (
      <div className='jobs-page'>
        {data ? (
          <>
            <div className='jobs-page-title'>
              <h1>Job opportunities</h1>
              <h2>with us and our partners</h2>
            </div>
            <div className='jobs-page-items'>{this.renderCompanies(data)}</div>
            <section className='routes clear-spacing-mb'>
              <div>
                <Link to='/about' className='routes-about-btn'>
                  <p>About us</p>
                  <img src={leftArrow} alt='arrow' />
                </Link>
                <Link to='/monsoon-tech' className='routes-monsoon-tech-btn'>
                  <p>Monsoon Tech</p>
                  <img src={leftArrow} alt='arrow' />
                </Link>
              </div>
            </section>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter((props) => <Jobs {...props} />);

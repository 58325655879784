import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Api from '../api/api.js';
import TeamPopUp from '../components/teamPopup.js';

import leftArrow from './../assets/img/contact-arrow-left.png';

class Team extends React.Component {
  state = {
    data: '',
    companyCounter: '',
    titleData: '',
    teamPopUpIndex: null,
  };

  isSlugValid = (slug, data) => {
    let index = null;
    data.forEach((item, i) => {
      if (item.slug === slug) {
        index = i;
      }
    });
    return index;
  };

  openPopupGivenSlug = () => {
    const {
      location: { pathname },
    } = this.props;
    const pathNameSplit = pathname.split('/team/');
    const slug = pathNameSplit[1];
    const { data } = this.state;
    if (slug) {
      const slugIndex = this.isSlugValid(slug, data);
      if (slugIndex !== null) {
        this.setState({ teamPopUpIndex: slugIndex });
      }
    }
    return false;
  };

  componentDidMount() {
    document.title = 'Team / Razor Capital';

    Api.getTeamMembersData().then((response) =>
      this.setState({ data: response.data }, this.openPopupGivenSlug)
    );

    Api.getTeamPageData().then((response) =>
      this.setState({ titleData: response.data[0].acf })
    );
  }

  createMarkup = (data) => {
    return { __html: data };
  };

  closeTeamPopup = () => {
    this.setState({ teamPopUpIndex: null });
  };

  getMemberNumber = (data) => {
    return data.filter(
      (item) => item.acf.team_member_type === 'executive-partners'
    );
  };

  render() {
    const { data, titleData, teamPopUpIndex } = this.state;
    return (
      <div className='team-page'>
        {data && titleData ? (
          <>
            {teamPopUpIndex === null ? (
              <></>
            ) : (
              <TeamPopUp
                key={'team-pop-' + teamPopUpIndex}
                index={teamPopUpIndex}
                data={data}
                close={this.closeTeamPopup}
              />
            )}
            <div className='team-page-title'>
              <h1
                dangerouslySetInnerHTML={this.createMarkup(
                  titleData.hero_title
                )}
              />
              <p
                dangerouslySetInnerHTML={this.createMarkup(
                  titleData.hero_description
                )}
              />
            </div>

            <section className='team-page-content'>
              <section className='team-page-content-upper clear-spacing-mb'>
                {window.innerWidth > 960 ? (
                  <>
                    <section className='team-page-content-upper-images'>
                      <div className='team-page-content-upper-images-wrapper'>
                        {data.map((item, i) => {
                          if (
                            item.acf.team_member_type === 'executive-partners'
                          ) {
                            return (
                              <img
                                style={{
                                  width:
                                    100 / this.getMemberNumber(data).length +
                                    '%',
                                }}
                                onClick={() =>
                                  this.setState({ teamPopUpIndex: i })
                                }
                                alt={item.acf.team_member_type}
                                className='team-page-content-upper-images-item cursor-pointer'
                                src={item.acf.team_member_featured_image}
                              />
                            );
                          }
                          return '';
                        })}
                      </div>
                    </section>
                    <div className='team-page-content-upper-content'>
                      {data.map((item, i) => {
                        if (
                          item.acf.team_member_type === 'executive-partners'
                        ) {
                          return (
                            <div
                              style={{
                                width:
                                  100 / this.getMemberNumber(data).length + '%',
                              }}
                              onClick={() =>
                                this.setState({ teamPopUpIndex: i })
                              }
                              className='team-page-content-upper-content-item cursor-pointer'
                            >
                              <h2>{item.title.rendered}</h2>
                              <p>{item.acf.team_member_role}</p>
                            </div>
                          );
                        }
                        return '';
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <section className='team-page-content-upper-member'>
                      {data.map((item, i) => {
                        if (
                          item.acf.team_member_type === 'executive-partners'
                        ) {
                          return (
                            <div
                              onClick={() =>
                                this.setState({ teamPopUpIndex: i })
                              }
                              className='team-page-content-upper-member-item clear-spacing-mb'
                            >
                              <div className='team-page-content-upper-member-item-image'>
                                <img
                                  alt={item.acf.team_member_type}
                                  className='team-page-content-upper-images-item cursor-pointer'
                                  src={item.acf.team_member_featured_image}
                                />
                              </div>
                              <div className='team-page-content-upper-member-item-details cursor-pointer'>
                                <h2>{item.title.rendered}</h2>
                                <p>{item.acf.team_member_role}</p>
                              </div>
                            </div>
                          );
                        }
                        return '';
                      })}
                    </section>
                  </>
                )}
              </section>
              <div className='team-page-content-lower'>
                <div className='team-page-content-lower-nonexecutive'>
                  <h2 className='team-page-content-lower-nonexecutive-title'>
                    Founding Team
                  </h2>
                  <div className='team-page-content-lower-nonexecutive-items'>
                    {data.map((item, i) => {
                      if (
                        (item.acf.team_member_type ===
                          'non-executive-directors' ||
                          item.acf.team_member_type === 'executive-partners') &&
                        item.acf.founding_team
                      ) {
                        return (
                          <div
                            onClick={() => this.setState({ teamPopUpIndex: i })}
                            className='team-page-content-lower-nonexecutive-item cursor-pointer'
                          >
                            {item.title.rendered}
                          </div>
                        );
                      }
                      return '';
                    })}
                  </div>
                </div>
              </div>
            </section>

            <section className='routes clear-spacing-mb'>
              <div>
                <Link to='/about' className='routes-about-btn'>
                  <p>About us</p>
                  <img src={leftArrow} alt='arrow' />
                </Link>
                <Link to='/monsoon-tech' className='routes-monsoon-tech-btn'>
                  <p>Monsoon Tech</p>
                  <img src={leftArrow} alt='arrow' />
                </Link>
              </div>
            </section>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter((props) => <Team {...props} />);
